import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Router from "./Router";

function App() {
    return (
        <div className="App">
            <Router />
        </div>
    );
}

export default App;
