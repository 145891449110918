import React from "react";
import {Navbar, Nav, Image} from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBlog, faHouse, faIndianRupeeSign, faCheckToSlot, faRightToBracket} from "@fortawesome/free-solid-svg-icons";

import "../css/style.css";

function NavBar() {
    return (
        <Navbar expand="lg" className="bg-custom-light">
            <Navbar.Brand href="/" style={{marginLeft: "3rem", fontSize: "1.15rem"}}>
                <Image src="/favicon.ico" className="icon" rounded /> Digital ATL Incharge
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/" style={{
                        color: window.location.pathname === "/" ? "#000": "rgba(0, 0, 0, 0.65)",
                    }}>
                        <FontAwesomeIcon icon={faHouse}/> Home
                    </Nav.Link>

                    <Nav.Link href="/buy" style={{
                        color: window.location.pathname === "/buy" ? "#000": "rgba(0, 0, 0, 0.65)",
                    }}>
                        <FontAwesomeIcon icon={faIndianRupeeSign}/> Buy
                    </Nav.Link>

                    <Nav.Link href="/bookslot" style={{
                        color: window.location.pathname === "/bookslot" ? "#000": "rgba(0, 0, 0, 0.65)",
                    }}>
                        <FontAwesomeIcon icon={faCheckToSlot}/> Book Slot
                    </Nav.Link>

                    <Nav.Link href="https://app.hamaralabs.com/" style={{
                        color: "rgba(0, 0, 0, 0.65)",
                    }}>
                        <FontAwesomeIcon icon={faRightToBracket}/> Login
                    </Nav.Link>

                    <Nav.Link href="/blog" style={{
                        color: window.location.pathname === "/blog" ? "#000": "rgba(0, 0, 0, 0.65)",
                    }}>
                        <FontAwesomeIcon icon={faBlog}/> Blog
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavBar;
