import React, {useEffect} from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Bars} from "react-loader-spinner";

import NavBar from "./components/NavBar";

const Home = React.lazy(() => import("./routes/Home"));
const Buy = React.lazy(() => import("./routes/Buy"));
const Blog = React.lazy(() => import("./routes/Blog"));
const PrivacyPolicy = React.lazy(() => import("./routes/PrivacyPolicy"));
const TermsAndConditions = React.lazy(() => import("./routes/TermsAndConditions"));
const Slotbook = React.lazy(() => import("./routes/SlotBook"));

const Success = React.lazy(() => import("./routes/Success"));
const Failed = React.lazy(() => import("./routes/Failed"));

const NotFoundPage = React.lazy(() => import("./routes/NotFoundPage"));

const VidyarangPlans = React.lazy(() => import("./routes/vidyarang/payments"));
const VidyarangPayments = React.lazy(() => import("./routes/vidyarang/paymentRedirect"));

const Checkout = React.lazy(() => import("./partials/Checkout"));

function Payment () {
    const [searchParams] = useSearchParams();

    const merchantTransactionId = searchParams.get("merchantTransactionId");
    const merchantId = searchParams.get("merchantId");

    useEffect(() => {
        (async () => {
            const response = await axios.post("https://us-central1-hamaralabs-prod.cloudfunctions.net/paymentIntegration/getStatus", {
                merchantTransactionId: merchantTransactionId,
                merchantId: merchantId
            });
            
            if (response.data.data.code === "PAYMENT_SUCCESS") {
                const getUserEmail = await axios.post("https://us-central1-hamaralabs-prod.cloudfunctions.net/paymentIntegration/getDoc", {
                    collection: "purchaseData",
                    id: merchantTransactionId
                }).catch((error) => {
                    console.log(error);
                });
                const userEmail = getUserEmail.data.data.email;
                console.log(userEmail);
                
                const userResponse = await axios.post("https://us-central1-hamaralabs-prod.cloudfunctions.net/paymentIntegration/createUser", {
                    email: userEmail
                }).catch(() => {
                    window.location.href = "https://hamaralabs.com/payments/failed";
                });
    
                if (userResponse.data.success === true) {
                    await axios.post("https://us-central1-hamaralabs-prod.cloudfunctions.net/paymentIntegration/addDoc", {
                        collection: "atlUsers",
                        id: userResponse.data.user.uid,
                        data: {
                            email: userEmail,
                            uid: userResponse.data.user.uid,
                            role: "student"
                        }
                    });
                    window.location.href = "https://hamaralabs.com/payments/success?email=" + userEmail;
                }
            } else {
                window.location.href = "https://hamaralabs.com/payments/failed";
            }
        })();
    });

    return (
        <div style={{height: "85%", display: "flex", alignItems: "center", justifyContent: "center"}}>
            {<Bars
                height="80"
                width="80"
                radius="9"
                color="black"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
            />}
        </div>
    );
}

function AppRouter() {
    return (
        <React.Suspense>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<><NavBar/><Home /></>} />
                    <Route path="/buy" element={<><NavBar/><Buy /></>} />
                    <Route path="/privacy-policy" element={<><NavBar/><PrivacyPolicy /></>} />
                    <Route path="/payments/success" element={<><NavBar/><Success /></>} />
                    <Route path="/terms-and-conditions" element={<><NavBar/><TermsAndConditions /></>} />
                    <Route path="/payment" element={<><NavBar/><Payment /></>} />
                    <Route path="/payments/failed" element={<><NavBar/><Failed /></>} />
                    <Route path="/bookslot" element={<><NavBar/><Slotbook/></>}></Route>
                    <Route path="/payment/checkout" element={<Checkout />}></Route>
                    <Route path="/vidyarang/payment" element={<VidyarangPayments />} />
                    <Route path="/vidyarang/store" element={<VidyarangPlans/>} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </BrowserRouter>
        </React.Suspense>
    );
}

export default AppRouter;
